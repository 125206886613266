<!-- <bc-header [menuInteraction]='false' class="Dark">
    <bc-header-left logoName="bancolombia-horizontal" ></bc-header-left>
    <bc-header-list>
        <bc-header-item icon="help" text="Ayuda" route="home"></bc-header-item>

    </bc-header-list>
</bc-header> -->

<!-- <bc-header [menuInteraction]='false' class="Dark">
    <bc-header-left logoName="bancolombia-horizontal" ></bc-header-left>
    <bc-header-list>
        <bc-header-item icon="help" text="Ayuda" route="home"></bc-header-item>

    </bc-header-list>
</bc-header> -->

<bc-header [menuInteraction]="true" [fixed]="false">
  <bc-header-left [ariaLabelogo]='ariaLabelogo' [ariaLabelChannel]='ariaLabelChannelName' logoName="bancolombia-horizontal" channelName="Vendor arrendamiento">
  </bc-header-left>
  <bc-header-list>
    <bc-header-item icon="help" text="Ayuda" route="home"></bc-header-item>
    <ul class="bc-header-nav-item">
      <div>
        <bc-float-menu [options]=firstOptions1>
          <div class="bc-float-menu-toggle bc-icon bc-flex bc-align-items-center" aria-controls="myDropdown3">
            <img class="img" bc-avatar sizeAvatar="small" src="assets/images/avatar_profile.png" alt="avatar-puffy" class="bc-mx-1" /><em
              class="bc-icon bc-sm">arrow2-down</em>
          </div>
        </bc-float-menu>
      </div>
    </ul>
  </bc-header-list>
</bc-header>
